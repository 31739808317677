import React, { useEffect, useContext, useReducer, useRef, useState } from "react"
import { dataFetchReducer } from "../../Utils/requestUtils"
import AuthContext from "../../../context/auth-context"
import { useParams, useHistory } from "react-router-dom"
import axios from "axios"
import CreditAnalysisClientProfile from "./ClientProfile/ClientProfile"
import {CircularProgress } from '@material-ui/core'
import Summary from "./Summary/Summary"
import Indicators from "../Cards/Indicators/Indicators"
import Boavista from "./Cards/BoavistaCard/Boavista";
import BoavistaNoData from "./Cards/BoavistaCard/BoavistaNoData";
import SCR from "./Cards/SCRCard/SCR"
import Serasa from "./Cards/SerasaCard/Serasa"
import Observation from "./Observation/Observation"
import ErrorBoundary from '../../Utils/ErrorBoundary'
import { getProperMetadata, getPDFHeader, colorDarkBlueQI, objetoVazio } from "../utils/utils"
import CreditProposalCard from "../Cards/CreditProposalCard/CreditProposalCard"
import DAFNCard from "../Cards/DAFNCard/DAFNCard"
import ProtestsCard from "../Cards/ProtestsCard/ProtestsCard"
import CustomDataCard from "../Cards/CustomDataCard/CustomDataCard"
import ReactToPrint from "react-to-print"
import SummaryToPDF from "./Summary/SummaryToPDF"
import SCRtoPDF from "./Cards/SCRCard/SCRtoPDF"
import SerasaToPDF from "./Cards/SerasaCard/SerasaToPDF"
import Button from "../utils/Components/Button"
import { useTranslation } from "react-i18next"
import Quod from "../Cards/Quod/Quod"
import AnalysisCardContainer from "../utils/Components/AnalysisCardContainer"

const blacklist_ck_not_show_observation_card = [
    '19ebfaee-4df6-4c74-83b4-0727e4bbbbc5', // ck Credit BR
    // "123-123-123", //Zaig local teste,
]

function CreditAnalysisLegalPerson(props) {

    const { t } = useTranslation()

    const [expandedSCR, setExpandedSCR] = useState(true)
    const [expandedSerasa, setExpandedSerasa] = useState(true)
    const [expandedQuod, setExpandedQuod] = useState(true)
    const [scoreToImg, setScoreToImg] = useState("")
    const [scrToExpire2Img, setScrToExpire2Img] = useState("")
    const [scrOverdue2Img, setScrOverdue2Img] = useState("")
    const [serasaScore2Img, setSerasaScore2Img] = useState("")
    const [serasaProbability2Img, setSerasaProbability2Img] = useState("")
    const [scrHistoryTotal2Img, setScrHistoryTotal2Img] = useState("")
    const [scrHistoryToExpireOverdue2Img, setScrHistoryToExpireOverdue2Img] = useState("")
    const [hasSummaryScoreImg, setHasSummaryScoreImg] = useState(false)
    const [hasSCRImg, setHasSCRImg] = useState(false)
    const [hasSCRHistoryImg, setHasSCRHistoryImg] = useState(false)
    const [hasSerasaImg, setHasSerasaImg] = useState(false)
    const [preparePDF, setPreparePDF] = useState(false)
    const [expandedBoavista, setExpandedBoavista] = useState(false);

    const pdf = useRef()

    let history = useHistory()
    let user_data = useContext(AuthContext).user_data

    let company_key = useContext(AuthContext).user_data.company_key

    let showObservationCard = !(blacklist_ck_not_show_observation_card.includes(company_key))

    if (!user_data.roles.includes("read_credit_analysis_legal_persons")) {
        history.push("")
    }

    let { credit_proposal_legal_person_key } = useParams()

    const [credit_analysis, dispatchCreditAnalysis] = useReducer(
        dataFetchReducer,
        { fetchedData: null, isLoading: true, isError: false }
    )

    useEffect(() => {
        dispatchCreditAnalysis({ type: "data_fetch_init" })
        const timer = setTimeout(() => {
            axios.get('/dash/credit_analysis/legal_person/' + credit_proposal_legal_person_key).then(response => {
                dispatchCreditAnalysis({
                    type: "data_fetch_success",
                    payload: response.data
                })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchCreditAnalysis({ type: "data_fetch_failure_403" })
                else if ((error.response || {}).status === 404) dispatchCreditAnalysis({ type: "data_fetch_failure_404" })
                else dispatchCreditAnalysis({ type: "data_fetch_failure" })
            })
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [credit_proposal_legal_person_key])

    const [buttonExport2PDF, setButtonExport2PDF] = useState(false)

    let showExportToPDFButton =
        hasSummaryScoreImg ?
            hasSCRImg ?
                hasSCRHistoryImg ?
                    hasSerasaImg ?
                        (!!scoreToImg && !!scrToExpire2Img && !!scrOverdue2Img && !!serasaScore2Img && !!serasaProbability2Img && !!scrHistoryTotal2Img && !!scrHistoryToExpireOverdue2Img)
                        : (!!scoreToImg && !!scrToExpire2Img && !!scrOverdue2Img && !!scrHistoryTotal2Img && !!scrHistoryToExpireOverdue2Img)
                    : hasSerasaImg ?
                        (!!scoreToImg && !!scrToExpire2Img && !!scrOverdue2Img && !!serasaScore2Img && !!serasaProbability2Img)
                        : (!!scoreToImg && !!scrToExpire2Img && !!scrOverdue2Img)
                : hasSerasaImg ?
                    (!!scoreToImg && !!serasaScore2Img && !!serasaProbability2Img)
                    : (!!scoreToImg)
            : hasSCRImg ?
                hasSCRHistoryImg ?
                    hasSerasaImg ?
                        (!!scrToExpire2Img && !!scrOverdue2Img && !!serasaScore2Img && !!serasaProbability2Img && !!scrHistoryTotal2Img && !!scrHistoryToExpireOverdue2Img)
                        : (!!scrToExpire2Img && !!scrOverdue2Img && !!scrHistoryTotal2Img && !!scrHistoryToExpireOverdue2Img)
                    : hasSerasaImg ?
                        (!!scrToExpire2Img && !!scrOverdue2Img && !!serasaScore2Img && !!serasaProbability2Img)
                        : (!!scrToExpire2Img && !!scrOverdue2Img)
                : hasSerasaImg ?
                    (!!serasaScore2Img && !!serasaProbability2Img)
                    : true

    useEffect(() => {
        if (preparePDF && showExportToPDFButton) {
            setTimeout(() => {
                setButtonExport2PDF(true)
            }, '15000')
        }
    }, [showExportToPDFButton, preparePDF])

    if (credit_analysis.fetchedData) {
        let decision_metadata = credit_analysis.fetchedData.credit_proposal_legal_person_analysis_events ? getProperMetadata(credit_analysis.fetchedData.credit_proposal_legal_person_analysis_events) : ""

        let hasAutomaticDecision = (credit_analysis.fetchedData.analysis_status === "automatically_approved" || credit_analysis.fetchedData.analysis_status === "automatically_reproved")
        const pageStyle = `
            @page {
                size: 210mm 290mm;
            }
            @media print {
                html, body {
                    height: initial !important;
                    overflow: initial !important;
                    -webkit-print-color-adjust: exact;
                }
                .avoidPageBreakInside {
                    page-break-inside: avoid;
                }
                .pagebreak {
                    page-break-before: always;
                }
            }
        `;

        return (
            <>
                <div style={{ minWidth: "900px", overflow: "hidden", paddingBottom: "30px" }}>
                    <div className="analysisCardContainer" style={{ display: "flex", justifyContent: 'space-between' }} >
                        <ErrorBoundary>
                            <div>
                                <div className={["blueText", "titleSize"].join(" ")} style={{ margin: "5px 0px" }}>
                                    {t("credit_analysis_legal_person_subtitle")}
                                    {user_data.business_group_key == null ? null : " - " + credit_analysis.fetchedData.company_name}
                                </div>
                                <div className={["labelText", "subtitleSize"].join(" ")} style={{ margin: "5px 0px" }}>
                                    {credit_analysis.fetchedData.is_manual_analysis
                                        ? `${t("manual_request")} `
                                        : t("client") + " #" + (credit_analysis.fetchedData.id ? credit_analysis.fetchedData.id : " - ")}
                                    {(credit_analysis.fetchedData || {}).created_at &&
                                        t("created_date", { created_date: credit_analysis.fetchedData.created_at })}
                                </div>
                            </div>
                            {preparePDF ?
                                <div style={{ display: 'flex' }}>
                                    <ReactToPrint
                                        trigger={() => (
                                            <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}>
                                                {buttonExport2PDF ?
                                                    <Button
                                                        button_option={'standard'}
                                                        buttonLabel={t("label_pdf_available")}
                                                    />
                                                    :
                                                    <Button
                                                        button_option={'standard_disabled_white'}
                                                        buttonLabel={t("label_loading_pdf")}
                                                    />
                                                }
                                            </div>
                                        )}
                                        content={() => pdf.current}
                                        pageStyle={pageStyle}
                                        documentTitle={`${t("credit_analysis_legal_person_subtitle")}_${credit_analysis.fetchedData.document_number}`}
                                    />
                                </div>
                                :
                                <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }}>
                                    <Button
                                        button_option={'standard'}
                                        buttonLabel={t("label_export_pdf")}
                                        onClick={() => { setExpandedSCR(true); setExpandedSerasa(true); setPreparePDF(true) }}
                                    />
                                </div>
                            }
                        </ErrorBoundary>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns:"32.5% 35% 32.5%" }}>
                        <div style={{ display: "flex", flexDirection:"column" }}>
                            <AnalysisCardContainer grow={true}>
                                <CreditProposalCard credit_analysis={credit_analysis.fetchedData}/>
                            </AnalysisCardContainer>
                            {credit_analysis.fetchedData.custom_data&&
                            <AnalysisCardContainer grow={true}>
                                <CustomDataCard data={credit_analysis.fetchedData} />
                            </AnalysisCardContainer>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <AnalysisCardContainer grow={true}>
                                <CreditAnalysisClientProfile credit_analysis={credit_analysis.fetchedData} />
                            </AnalysisCardContainer>
                            <AnalysisCardContainer grow={true}>
                                <Summary credit_analysis={credit_analysis.fetchedData} preparePDF={preparePDF} setScoreToImg={setScoreToImg} setHasSummaryScoreImg={setHasSummaryScoreImg} />
                            </AnalysisCardContainer>
                        </div>
                        <div style={{ flexGrow: "1" }} className="analysisCardContainer">
                            <ErrorBoundary>
                                <Indicators indicators={decision_metadata.indicators} />
                            </ErrorBoundary>
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", gap:"2px"}}>
                        {(decision_metadata || {}).protests &&
                            <AnalysisCardContainer grow={true}>
                                <ProtestsCard protests={decision_metadata.protests} isPDF={false} />
                            </AnalysisCardContainer>}
                        {(decision_metadata || {}).dafn &&
                            <AnalysisCardContainer grow={true}>
                                <DAFNCard dafn={decision_metadata.dafn} isPDF={false} person_type={"legal"} />
                            </AnalysisCardContainer>}
                        {(decision_metadata || {}).quod &&
                            <AnalysisCardContainer grow={true}>
                                <Quod quod={decision_metadata.quod} expandedQuod={expandedQuod} setExpandedQuod={setExpandedQuod}/>
                            </AnalysisCardContainer>}
                        {(decision_metadata || {}).serasa?.document_number &&
                            <AnalysisCardContainer grow={true}>
                                <Serasa
                                    serasa={decision_metadata.serasa}
                                    setExpandedSerasa={setExpandedSerasa}
                                    expandedSerasa={expandedSerasa}
                                    preparePDF={preparePDF}
                                    setSerasaScore2Img={setSerasaScore2Img}
                                    setSerasaProbability2Img={setSerasaProbability2Img}
                                    setHasSerasaImg={setHasSerasaImg}
                                />
                            </AnalysisCardContainer>}
                            {(decision_metadata || {}).boavista ?
                                (!objetoVazio(decision_metadata.boavista) && (decision_metadata.boavista.data.length) === 1) ?
                                    (<div className="analysisCardContainer pagebreak">
                                    <ErrorBoundary>
                                        <Boavista
                                        boavista={decision_metadata.boavista}
                                        setExpandedBoavista={setExpandedBoavista}
                                        expandedBoavista={expandedBoavista}
                                        />
                                    </ErrorBoundary>
                                    </div>)
                                    :
                                    (<div className="analysisCardContainer"><ErrorBoundary><BoavistaNoData /></ErrorBoundary></div>)
                                : null}
                        {(decision_metadata || {}).scr &&
                            <AnalysisCardContainer grow={true}>
                                <SCR
                                    scr={decision_metadata.scr}
                                    setExpandedSCR={setExpandedSCR}
                                    expandedSCR={expandedSCR}
                                    preparePDF={preparePDF}
                                    setScrToExpire2Img={setScrToExpire2Img}
                                    setScrOverdue2Img={setScrOverdue2Img}
                                    setScrHistoryTotal2Img={setScrHistoryTotal2Img}
                                    setScrHistoryToExpireOverdue2Img={setScrHistoryToExpireOverdue2Img}
                                    setHasSCRImg={setHasSCRImg}
                                    setHasSCRHistoryImg={setHasSCRHistoryImg}
                                />
                            </AnalysisCardContainer>}
                        {!!showObservationCard && !hasAutomaticDecision&&
                            <AnalysisCardContainer grow={true}>
                                <Observation credit_analysis={credit_analysis.fetchedData} credit_proposal_legal_person_key={credit_proposal_legal_person_key} user_data={user_data} />
                            </AnalysisCardContainer>}
                    </div>
                </div>
                {preparePDF &&
                    <div style={{ display: 'none' }}>
                        <div style={{ paddingBottom: "30px" }} ref={pdf}>
                            <div>{getPDFHeader(t("credit_analysis_legal_person_subtitle"), colorDarkBlueQI)}</div>
                            <div style={{display:"flex", flexDirection:"column", gap:"2px"}}>
                                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                                    <CreditProposalCard credit_analysis={credit_analysis.fetchedData}/>
                                </AnalysisCardContainer>
                                {credit_analysis.fetchedData.custom_data&&
                                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                                    <CustomDataCard data={credit_analysis.fetchedData} />
                                </AnalysisCardContainer>}
                                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                                    <CreditAnalysisClientProfile credit_analysis={credit_analysis.fetchedData}/>
                                </AnalysisCardContainer>
                                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                                    <SummaryToPDF credit_analysis={credit_analysis.fetchedData} scoreToImg={scoreToImg}/>
                                </AnalysisCardContainer>
                                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                                    <Indicators indicators={decision_metadata.indicators}/>
                                </AnalysisCardContainer>
                                {(decision_metadata || {}).protests&&
                                    <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                                        <ProtestsCard protests={decision_metadata.protests} />
                                    </AnalysisCardContainer>}
                                {(decision_metadata || {}).dafn&&
                                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                                    <DAFNCard dafn={decision_metadata.dafn} person_type={"legal"} />
                                </AnalysisCardContainer>}
                                {(decision_metadata || {}).quod&&
                                <AnalysisCardContainer grow={false}>
                                    <Quod quod={decision_metadata.quod} expandedQuod={true} setExpandedQuod={setExpandedQuod} isPDF={true}/>
                                </AnalysisCardContainer>}
                                {(decision_metadata || {}).serasa?.document_number&&
                                <AnalysisCardContainer grow={false}>
                                    <SerasaToPDF
                                        serasa={decision_metadata.serasa}
                                        expandedSerasa={true}
                                        serasaScore2Img={serasaScore2Img}
                                        serasaProbability2Img={serasaProbability2Img}
                                    />
                                </AnalysisCardContainer>}
                                {(decision_metadata || {}).scr&&
                                <AnalysisCardContainer grow={false}>
                                    <SCRtoPDF
                                        scr={decision_metadata.scr}
                                        expandedSCR={true}
                                        scrToExpire2Img={scrToExpire2Img}
                                        scrOverdue2Img={scrOverdue2Img}
                                        scrHistoryTotal2Img={scrHistoryTotal2Img}
                                        scrHistoryToExpireOverdue2Img={scrHistoryToExpireOverdue2Img}
                                        isPDF={true}
                                    />
                                </AnalysisCardContainer>}
                                {!!showObservationCard && !hasAutomaticDecision &&
                                <AnalysisCardContainer className="analysisCardContainer avoidPageBreakInside" grow={false}>
                                    <Observation credit_analysis={credit_analysis.fetchedData} credit_proposal_legal_person_key={credit_proposal_legal_person_key} user_data={user_data} isPDF={true}/>
                                </AnalysisCardContainer>}
                            </div>
                        </div>
                    </div>}
            </>
        )
    }
    else if (credit_analysis.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
    else if (credit_analysis.isError) {
        return (
            <div style={{ height: "80vh", display: "flex" }}>
                <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
                    {/* CONFERIR ESSA TRADUÇÃO */}
                    {t(credit_analysis.errorMessage)}
                </p>
            </div>
        )
    }

    return null
}

export default CreditAnalysisLegalPerson
