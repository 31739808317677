import React from "react"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import PublishIcon from "@material-ui/icons/Publish"
import moment from "moment"
import getSymbolFromCurrency from "currency-symbol-map"
import html2canvas from "html2canvas"
import logoQI from "../../../assets/logoqi.svg"
import i18next from "i18next"

export const colorGreen = "rgb(27, 139, 61)"

export const colorRed = "rgb(217, 26, 26)"

export const colorGrey = "#6F6F6F"

export const colorYellow = "#e68b00"

export const colorBeige = "#e5ddcb"

export const colorBlueQIShadow = "rgb(79, 204, 237, 0.7)"

export const colorPinkQIShadow = "rgb(254, 64, 128, 0.7)"

export const colorDarkBlueQIShadow = "rgb(11, 31, 130, 0.7)"

export const colorDarkGreen = "#125a44"

export const colorDarkRed = "#c21b12"

export const colorDarkBlueQI = "rgb(11, 31, 130)"

export const credit_list_translations_function = (enumList) => {

  return enumList.map(eachEnum => i18next.t(eachEnum))
}

export const credit_type_enumList = ["clean", "student_loan", "credit_card_limit", "construction_materials", "solar_panels","fuel"]
export const interest_type_enumList = ["not_applied", "cdi_plus", "cdi_percentage", "price", "pre_fixed"]

export const analysis_status_type_enumList = [
  ['pending', 'pending'],
  ['waiting_for_data', 'waiting_for_data'],
  ['manually_approved', 'manually_approved'],
  ['manually_reproved', 'manually_reproved'],
  ['automatically_approved', 'automatically_approved'],
  ['automatically_reproved', 'automatically_reproved'],
  ['in_manual_analysis', 'in_manual_analysis'],
  ['automatically_challenged', 'automatically_challenged'],
  ['in_manual_analysis_before_rule_execution','in_manual_analysis_before_rule_execution'],
  ['manually_approved_before_rule_execution','manually_approved_before_rule_execution']
]

export const credit_enum_translations_function = (enumList) => {
  return enumList.map((eachEnumPair) => {
    return [i18next.t(eachEnumPair[0]), eachEnumPair[1]]
  })
}

export const credit_translations = {
  analysis_status: {
    automatically_approved: {
      description: "Aprovado Automaticamente",
      color: colorGreen,
      siglum: "A",
    },
    automatically_reproved: {
      description: "Reprovado Automaticamente",
      color: colorRed,
      siglum: "A",
    },
    in_manual_analysis: {
      description: "Pendente",
      color: colorGrey,
      siglum: "P",
    },
    pending: {
      description: "Em Processamento",
      color: colorGrey,
      siglum: "EP",
    },
    waiting_for_data: {
      description: "Aguardando Dados",
      color: colorGrey,
      siglum: "AD",
    },
    manually_approved: {
      description: "Aprovado Manualmente",
      color: colorGreen,
      siglum: "M",
    },
    manually_reproved: {
      description: "Reprovado Manualmente",
      color: colorRed,
      siglum: "M",
    },
    automatically_challenged: {
      description: "Contestado Automaticamente",
      color: colorGrey,
      siglum: "P"
    },
    in_manual_analysis_before_rule_execution: {
      description: "Em Análise Manual para a Execução da Regra",
      color: colorGrey,
      siglum: "P",
    },
    manually_approved_before_rule_execution: {
      description: "Aprovado Manualmente para a Execução da Regra",
      color: colorGrey,
      siglum: "P",
    },
    default: {
      description: "",
      color: colorGrey,
      siglum: "?",
    },
    enumList: [
      ["Em Processamento", "pending"],
      ["Aguardando Dados", "waiting_for_data"],
      ["Aprovado Manualmente", "manually_approved"],
      ["Reprovado Manualmente", "manually_reproved"],
      ["Aprovado Automaticamente", "automatically_approved"],
      ["Reprovado Automaticamente", "automatically_reproved"],
      ["Em Análise Manual", "in_manual_analysis"],
      ["Contestado Automaticamente", "automatically_challenged"],
      ["Em Análise Manual para a Execução da Regra", "in_manual_analysis_before_rule_execution"],
      ["Aprovado Manualmente para a Execução da Regra", "manually_approved_before_rule_execution"]
    ],
  },
  credit_proposal_status: {
    enumList: [
      ["Solicitada", "created"],
      ["Desembolsada", "disbursed"],
      ["Quitada", "paid"],
      ["Em atraso", "defaulted"],
    ],
    created: {
      description: "Solicitada",
      color: colorYellow,
      siglum: <PublishIcon style={{ width: "18px", height: "18px" }} />,
    },
    disbursed: {
      description: "Desembolsada",
      color: colorGreen,
      siglum: <CheckIcon style={{ width: "18px", height: "18px" }} />,
    },
    paid: {
      description: "Quitada",
      color: colorGreen,
      siglum: <CheckIcon style={{ width: "18px", height: "18px" }} />,
    },
    defaulted: {
      description: "Em atraso",
      color: colorRed,
      siglum: <ClearIcon style={{ width: "18px", height: "18px" }} />,
    },
  },
  credit_type: {
    enumList: [
      ["Estudantil", "student_loan"],
      ["Cartão de Crédito", "credit_card_limit"],
      ["Livre", "clean"],
      ["Material de Construção", "construction_materials"],
      ["Painéis Solares", "solar_panels"],
      ["Combustível", "fuel"],
    ],
    student_loan: "Estudantil",
    credit_card_limit: "Cartão de Crédito",
    clean: "Livre",
    construction_materials: "Material de Construção",
    solar_panels: "Painéis Solares",
    fuel: "Combustível",
  },
  interest_type: {
    enumList: [
      ["CDI+", "cdi_plus"],
      ["CDI%", "cdi_percentage"],
      ["Tabela Price", "price"],
      ["pré-fixado", "pre_fixed"],
    ],
    cdi_plus: "CDI+",
    cdi_percentage: "CDI%",
    price: "Tabela Price",
    pre_fixed: "pré-fixado",
  },
  currency: {
    enumList: [
      ["R$", "BRL"],
      ["U$", "USD"],
      ["€", "EUR"],
    ],
  },
  indicator: {
    positive: colorGreen,
    negative: colorRed,
    attention: colorYellow,
    neutral: colorYellow,
    default: colorGrey,
  },
  analyze_dialog_type: {
    np: {
      label: "CPF",
      validation_size: 14,
      mask_type: "999.999.999-99",
      post_endpoint: "natural_person",
      validation_obj_size: 11,
    },
    lp: {
      label: "CNPJ",
      validation_size: 18,
      mask_type: "99.999.999/9999-99",
      post_endpoint: "legal_person",
      validation_obj_size: 12,
    },
  },
  not_listed: {
    description: "",
    color: colorGrey,
    siglum: "?",
  },
  riskLevelClassification: {
    AA: { color: colorGrey },
    // A: {color: colorGreen}, // MOCK PDF
    A: { color: colorGrey },
    B: { color: colorGrey },
    C: { color: colorGrey },
    D: { color: colorGrey },
    E: { color: colorGrey },
    F: { color: colorGrey },
    G: { color: colorGrey },
    H: { color: colorGrey },
  },
}

export const credit_translations_pt_en = {
  "pt":
  {
    analysis_status: {
      automatically_approved: {
        description: "Aprovado Automaticamente",
        color: colorGreen,
        siglum: "A",
      },
      automatically_reproved: {
        description: "Reprovado Automaticamente",
        color: colorRed,
        siglum: "A",
      },
      in_manual_analysis: {
        description: "Pendente",
        color: colorGrey,
        siglum: "P",
      },
      pending: {
        description: "Em Processamento",
        color: colorGrey,
        siglum: "EP",
      },
      waiting_for_data: {
        description: "Aguardando Dados",
        color: colorGrey,
        siglum: "AD",
      },
      manually_approved: {
        description: "Aprovado Manualmente",
        color: colorGreen,
        siglum: "M",
      },
      manually_reproved: {
        description: "Reprovado Manualmente",
        color: colorRed,
        siglum: "M",
      },
      automatically_challenged: {
        description: "Contestado Automaticamente",
        color: colorGrey,
        siglum: "P",
      },
      in_manual_analysis_before_rule_execution: {
        description: "Em Análise Manual para a Execução da Regra",
        color: colorGrey,
        siglum: "P",
      },
      manually_approved_before_rule_execution: {
        description: "Aprovado Manualmente para a Execução da Regra",
        color: colorGrey,
        siglum: "P",
      },
      default: {
        description: "",
        color: colorGrey,
        siglum: "?",
      },
      enumList: [
        ["Em Processamento", "pending"],
        ["Aguardando Dados", "waiting_for_data"],
        ["Aprovado Manualmente", "manually_approved"],
        ["Reprovado Manualmente", "manually_reproved"],
        ["Aprovado Automaticamente", "automatically_approved"],
        ["Reprovado Automaticamente", "automatically_reproved"],
        ["Em Análise Manual", "in_manual_analysis"],
        ["Contestado Automaticamente", "automatically_challenged"],
        ["Em Análise Manual para a Execução da Regra", "in_manual_analysis_before_rule_execution"],
        ["Aprovado Manualmente para a Execução da Regra", "manually_approved_before_rule_execution"]
      ],
    },
    credit_proposal_status: {
      enumList: [
        ["Solicitada", "created"],
        ["Desembolsada", "disbursed"],
        ["Quitada", "paid"],
        ["Em atraso", "defaulted"],
      ],
      created: {
        description: "Solicitada",
        color: colorYellow,
        siglum: <PublishIcon style={{ width: "18px", height: "18px" }} />,
      },
      disbursed: {
        description: "Desembolsada",
        color: colorGreen,
        siglum: <CheckIcon style={{ width: "18px", height: "18px" }} />,
      },
      paid: {
        description: "Quitada",
        color: colorGreen,
        siglum: <CheckIcon style={{ width: "18px", height: "18px" }} />,
      },
      defaulted: {
        description: "Em atraso",
        color: colorRed,
        siglum: <ClearIcon style={{ width: "18px", height: "18px" }} />,
      },
    },
    credit_type: {
      enumList: [
        ["Estudantil", "student_loan"],
        ["Cartão de Crédito", "credit_card_limit"],
        ["Livre", "clean"],
        ["Material de Construção", "construction_materials"],
        ["Painéis Solares", "solar_panels"],
        ["Combustível", "fuel"],
      ],
      student_loan: "Estudantil",
      credit_card_limit: "Cartão de Crédito",
      clean: "Livre",
      construction_materials: "Material de Construção",
      solar_panels: "Painéis Solares",
      fuel:"Combustível"
    },
    interest_type: {
      enumList: [
        ["CDI+", "cdi_plus"],
        ["CDI%", "cdi_percentage"],
        ["Tabela Price", "price"],
        ["pré-fixado", "pre_fixed"],
      ],
      cdi_plus: "CDI+",
      cdi_percentage: "CDI%",
      price: "Tabela Price",
      pre_fixed: "pré-fixado",
    },
    currency: {
      enumList: [
        ["R$", "BRL"],
        ["U$", "USD"],
        ["€", "EUR"],
      ],
    },
    indicator: {
      positive: colorGreen,
      negative: colorRed,
      attention: colorYellow,
      neutral: colorYellow,
      default: colorGrey,
    },
    analyze_dialog_type: {
      np: {
        label: "CPF",
        validation_size: 14,
        mask_type: "999.999.999-99",
        post_endpoint: "natural_person",
        validation_obj_size: 11,
      },
      lp: {
        label: "CNPJ",
        validation_size: 18,
        mask_type: "99.999.999/9999-99",
        post_endpoint: "legal_person",
        validation_obj_size: 12,
      },
    },
    not_listed: {
      description: "",
      color: colorGrey,
      siglum: "?",
    },
    riskLevelClassification: {
      AA: { color: colorGrey },
      // A: {color: colorGreen}, // MOCK PDF
      A: { color: colorGrey },
      B: { color: colorGrey },
      C: { color: colorGrey },
      D: { color: colorGrey },
      E: { color: colorGrey },
      F: { color: colorGrey },
      G: { color: colorGrey },
      H: { color: colorGrey },
    },
  },
  "en": {
    analysis_status: {
      automatically_approved: {
        description: "Automatically Approved",
        color: colorGreen,
        siglum: "A",
      },
      automatically_reproved: {
        description: "Automatically Reproved",
        color: colorRed,
        siglum: "A",
      },
      in_manual_analysis: {
        description: "In Manual Analysis",
        color: colorGrey,
        siglum: "MA",
      },
      pending: {
        description: "Pending",
        color: colorGrey,
        siglum: "P",
      },
      waiting_for_data: {
        description: "Waiting Data",
        color: colorGrey,
        siglum: "WD",
      },
      manually_approved: {
        description: "Manually Approved",
        color: colorGreen,
        siglum: "M",
      },
      manually_reproved: {
        description: "Manually Reproved",
        color: colorRed,
        siglum: "M",
      },
      automatically_challenged: {
        description: "Automatically Challenged",
        color: colorGrey,
        siglum: "P"
      },
      in_manual_analysis_before_rule_execution: {
        description: "In Manual Analysis Before Rule Execution",
        color: colorGrey,
        siglum: "P",
      },
      manually_approved_before_rule_execution: {
        description: "Manually Approved Before Rule Execution",
        color: colorGrey,
        siglum: "P",
      },
      default: {
        description: "",
        color: colorGrey,
        siglum: "?",
      },
      enumList: [ // ARRUMAR
        ["Em Processamento", "pending"],
        ["Aguardando Dados", "waiting_for_data"],
        ["Aprovado Manualmente", "manually_approved"],
        ["Reprovado Manualmente", "manually_reproved"],
        ["Aprovado Automaticamente", "automatically_approved"],
        ["Reprovado Automaticamente", "automatically_reproved"],
        ["Em Análise Manual", "in_manual_analysis"],
        ["Contestado Automaticamente", "automatically_challenged"],
        ["Em Análise Manual para a Execução da Regra", "in_manual_analysis_before_rule_execution"],
        ["Aprovado Manualmente para a Execução da Regra", "manually_approved_before_rule_execution"]
      ],
    },
    credit_proposal_status: {
      enumList: [
        ["Solicitada", "created"],
        ["Desembolsada", "disbursed"],
        ["Quitada", "paid"],
        ["Em atraso", "defaulted"],
      ],
      created: {
        description: "Solicitada",
        color: colorYellow,
        siglum: <PublishIcon style={{ width: "18px", height: "18px" }} />,
      },
      disbursed: {
        description: "Desembolsada",
        color: colorGreen,
        siglum: <CheckIcon style={{ width: "18px", height: "18px" }} />,
      },
      paid: {
        description: "Quitada",
        color: colorGreen,
        siglum: <CheckIcon style={{ width: "18px", height: "18px" }} />,
      },
      defaulted: {
        description: "Em atraso",
        color: colorRed,
        siglum: <ClearIcon style={{ width: "18px", height: "18px" }} />,
      },
    },
    credit_type: {
      enumList: [
        ["Estudantil", "student_loan"],
        ["Cartão de Crédito", "credit_card_limit"],
        ["Livre", "clean"],
        ["Material de Construção", "construction_materials"],
        ["Painéis Solares", "solar_panels"],
        ["Combustível", "fuel"],
      ],
      student_loan: "Estudantil",
      credit_card_limit: "Cartão de Crédito",
      clean: "Livre",
      construction_materials: "Material de Construção",
      solar_panels: "Painéis Solares",
      fuel:"Combustivel"
    },
    interest_type: {
      enumList: [
        ["CDI+", "cdi_plus"],
        ["CDI%", "cdi_percentage"],
        ["Tabela Price", "price"],
        ["pré-fixado", "pre_fixed"],
      ],
      cdi_plus: "CDI+",
      cdi_percentage: "CDI%",
      price: "Tabela Price",
      pre_fixed: "pré-fixado",
    },
    currency: {
      enumList: [
        ["R$", "BRL"],
        ["U$", "USD"],
        ["€", "EUR"],
      ],
    },
    indicator: {
      positive: colorGreen,
      negative: colorRed,
      attention: colorYellow,
      neutral: colorYellow,
      default: colorGrey,
    },
    analyze_dialog_type: {
      np: {
        label: "CPF",
        validation_size: 14,
        mask_type: "999.999.999-99",
        post_endpoint: "natural_person",
        validation_obj_size: 11,
      },
      lp: {
        label: "CNPJ",
        validation_size: 18,
        mask_type: "99.999.999/9999-99",
        post_endpoint: "legal_person",
        validation_obj_size: 12,
      },
    },
    not_listed: {
      description: "",
      color: colorGrey,
      siglum: "?",
    },
    riskLevelClassification: {
      AA: { color: colorGrey },
      // A: {color: colorGreen}, // MOCK PDF
      A: { color: colorGrey },
      B: { color: colorGrey },
      C: { color: colorGrey },
      D: { color: colorGrey },
      E: { color: colorGrey },
      F: { color: colorGrey },
      G: { color: colorGrey },
      H: { color: colorGrey },
    },
  }
}

export const getCreditAnalysisTranslationObj = (user_language) => {
  return credit_translations_pt_en[user_language]
}


export const serasa_translations = {
  serasa_doc_status: {
    regular: {
      color: colorGreen,
    },
    void: {
      color: colorGrey,
    },
    not_found: {
      color: colorGrey,
    },
    inactive: {
      color: colorRed,
    },
    inapt: {
      color: colorRed,
    },
    liquidating: {
      color: colorRed,
    },
    dead: {
      color: colorRed,
    },
    suspended: {
      color: colorRed,
    },
    canceled: {
      color: colorRed,
    },
    pending_regularization: {
      color: colorRed,
    },
  },
  risk_level: {
    Iminente: colorRed,
    Relevante: colorRed,
    Considerável: colorRed,
    Médio: colorYellow,
    Baixo: colorGreen,
    "Muito Baixo": colorGreen,
    Mínimo: colorGreen,
    "imminent_risk": colorRed,
    "relevant_risk": colorRed,
    "considerable_risk": colorRed,
    "medium_risk": colorYellow,
    "low_risk": colorGreen,
    "very_low_risk": colorGreen,
    "minimal_risk": colorGreen,
  },
  positive: {
    color: colorGreen,
    siglum: <CheckIcon style={{ color: colorGreen }} />,
  },
  negative: {
    siglum: <ErrorOutlineIcon style={{ color: colorRed }} />,
  },
  neutral: {
    siglum: <ErrorOutlineIcon style={{ color: colorYellow }} />,
  },
  default: {
    siglum: <ErrorOutlineIcon style={{ color: colorGrey }}/>,
  },
}

export const boavista_translations = {
  positive: {
    color: colorGreen,
    siglum: <CheckIcon style={{ color: colorGreen }} />,
  },
  negative: {
    siglum: <ErrorOutlineIcon style={{ color: colorRed }} />,
  }
}

export const getCardIcon = (type) => {
  switch (type) {
    case "positive":
    case false:
    case "false":
      return <CheckIcon style={{ color: colorGreen }} />
    case "negative":
    case true:
    case "true":
      return <ErrorOutlineIcon style={{ color: colorRed }} />
    case "neutral":
      return <ErrorOutlineIcon style={{ color: colorYellow }} />
    default:
      return <ErrorOutlineIcon />
  }
}



export const getIndicatorsFlagColor = (flag) => {
  switch (flag) {
    case "positive":
      return colorDarkGreen
    case "negative":
      return colorDarkRed
    case "neutral":
      return colorYellow
    default:
      return colorGrey
  }
}

export const getIndicatorsFlagIcon = (flag, margin = null) => {
  let margin_ = "auto"
  if (margin != null) {
    margin_ = margin
  }
  switch (flag) {
    case "positive":
      return (
        <CheckIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorDarkGreen,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: margin_,
            marginLeft: margin_,
          }}
        />
      )
    case "negative":
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorDarkRed,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: margin_,
            marginLeft: margin_,
          }}
        />
      )
    case "neutral":
    case "attention":
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorYellow,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: margin_,
            marginLeft: margin_,
          }}
        />
      )
    default:
      return (
        <ErrorOutlineIcon
          style={{
            verticalAlign: "middle",
            width: "20px",
            height: "20px",
            color: colorGrey,
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: margin_,
            marginLeft: margin_,
          }}
        />
      )
  }
}

export const getConfirmationIcon = (status) => {
  if (status) {
    return (
      <CheckIcon
        className="fraudStatusIcon"
        style={{ color: colorGreen, width: "40px", height: "40px" }}
      />
    )
  } else {
    return (
      <ClearIcon
        className="fraudStatusIcon"
        style={{ color: colorRed, width: "40px", height: "40px" }}
      />
    )
  }
}

export const setAllowChanges = (status, roles, type) => {
  if (type === "np") {
    if (!roles.includes("update_credit_analysis_natural_persons")) {
      return false
    }
  } else {
    if (!roles.includes("update_credit_analysis_legal_persons")) {
      return false
    }
  }

  switch (status) {
    case "in_manual_analysis":
      return true
    default:
      return false
  }
}

export const formatAsMoneyMinimum2Digits = (value, nullCase = null) => {
  if (value == null) return nullCase
  return (
    "R$ " + (value / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2 })
  )
}

export const formatAsMoneyMaximun2Digits = (
  currency,
  value,
  nullCase = null
) => {
  let symbolFromCurrency = getSymbolFromCurrency(currency)
  if (value == null) return nullCase
  if (value)
    return (
      symbolFromCurrency +
      " " +
      (value / 100).toLocaleString("pt-BR", { maximumFractionDigits: 2 })
    )
}

export const formatAsMoney = (currency, value, nullCase = null) => {
  // let symbolFromCurrency = getSymbolFromCurrency(currency)
  if (value == null) return nullCase
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value / 100)
}

export const formatAsMoneyWithoutCurrency = (value, nullCase = null) => {
  if (value == null) return nullCase
  return String(parseFloat(value/100).toFixed(2)).replace(".",",")
}

export const formatBoavistaData = (key, value) => {
  if (value) {
    if (key.includes("capital")) return formatAsMoney("BRL", value)
    if (key.includes("amount")) return formatAsMoney("BRL", value)
    else if (key.includes("date")) return moment(value).format("DD/MM/YYYY")
    else if (key.includes("name")) {
      const arr = value.toLowerCase().split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ")
    }
    else if (key.includes("state")) {
      return value.toUpperCase()
    }
    else if (key.includes("document_number")){
      let doc = value.replace(/\D/g, '')
      if (doc.length===11) {
        return doc.slice(0,3)+"."+doc.slice(3,6)+"."+doc.slice(6,9)+"-"+doc.slice(9,11)
      }
      else {
        return doc.slice(0,2)+"."+doc.slice(2,5)+"."+doc.slice(5,8)+"/"+doc.slice(8,12)+"-"+doc.slice(12,14)
      }
    }
    else if (key.includes("ibge_city_code")) {
      if (value ==="00000000") return " - "
    }
    else if (key.includes("voter_number")) {
      if (value==="0000000000000") return " - "
    }
    else return value
  }
  else return " - "
}

export const formatScorePositivoData = (key, value) => {
  if (value) {
    if (key.includes("amount")) return formatAsMoney("BRL", value)
    else if (key.includes("date")) return moment(value).format("DD/MM/YYYY")
    else if (key.includes("name")) {
      const arr = value.toLowerCase().split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ")
    }
    else if (key.includes("state")) {
      return value.toUpperCase()
    }
    else if (key.includes("document_number")){
      let doc = value.replace(/\D/g, '')
      if (doc.length===11) {
        return doc.slice(0,3)+"."+doc.slice(3,6)+"."+doc.slice(6,9)+"-"+doc.slice(9,11)
      }
      else {
        return doc.slice(0,2)+"."+doc.slice(2,5)+"."+doc.slice(5,8)+"/"+doc.slice(8,12)+"-"+doc.slice(12,14)
      }
    }
    else if (key.includes("ibge_city_code")) {
      if (value ==="00000000") return " - "
    }
    else if (key.includes("voter_number")) {
      if (value==="0000000000000") return " - "
    }
    else return value
  }
  else return " - "
}

export const formatToUpperFirstLetter = (value) => {
  const arr = value.toLowerCase().split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ")
}

export const objetoVazio = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}

export const getProperMetadata = (status_events) => {
  let nonNullMetadata = status_events.filter(
    (event) => event.decision_metadata != null
  )
  if (nonNullMetadata.length === 0) return {}
  else if (nonNullMetadata.length === 1)
    return nonNullMetadata[0].decision_metadata
  let finalMetadata = nonNullMetadata.sort((a, b) =>
    moment(b.created_at).diff(moment(a.created_at))
  )
  return finalMetadata[0].decision_metadata
}

export function replaceAll(str, mapObj) {
  var re = new RegExp(Object.keys(mapObj).join("|"), "gi")

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()]
  })
}

export const titleCase = (str, nullCase) => {
  if (str == null) {
    return nullCase
  }
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(" ")
}

export const months = [ "jan", "feb", "mar", "april", "may", "jun", "jul", "aug", "sept", "oct", "nov", "dec"]

export const formatAsDate = (reference_date) => {
  const {t} = i18next
  reference_date = reference_date.split("-")
  let month = months[reference_date[1] - 1]
  return `${t(month)} ${reference_date[0]}`
}

export const getDateFormated = (date_in_english) => {
  let day = date_in_english.split("/")[0] 
  let month = date_in_english.split("/")[1] 
  let year = date_in_english.split("/")[2] 
  return year + "-" + month + "-" + day
} 

export const getMapRiskCalculationTreeObj = (risk_obj) => {

  var mappedRiskCalculationTree = {
    total_risk_sum: risk_obj["total_risk"]["sum"],
    total_responsability_sum: risk_obj["total_risk"]["total_responsability"]["sum"],
    loan_portfolio_sum: risk_obj["total_risk"]["total_responsability"]["loan_portfolio"]["sum"],
    active_credit_sum: risk_obj["total_risk"]["total_responsability"]["loan_portfolio"]["active_credit"]["sum"],
    credit_to_be_expired_modality_01_to_13_obj: risk_obj["total_risk"]["total_responsability"]["loan_portfolio"]["active_credit"]["credit_to_be_expired_modality_01_to_13"],
    credit_overdue_modality_01_to_13_obj: risk_obj["total_risk"]["total_responsability"]["loan_portfolio"]["active_credit"]["credit_overdue_modality_01_to_13"],
    financial_loss_modality_14_obj: risk_obj["total_risk"]["total_responsability"]["loan_portfolio"]["financial_loss_modality_14"],
    interfinancial_transfers_sum: risk_obj["total_risk"]["total_responsability"]["interfinancial_transfers"]["sum"],
    credit_to_be_released_modality_14_obj: risk_obj["total_risk"]["total_responsability"]["interfinancial_transfers"]["credit_to_be_released_modality_14"],
    credit_to_be_expired_modality_14_obj: risk_obj["total_risk"]["total_responsability"]["interfinancial_transfers"]["credit_to_be_expired_modality_14"],
    credit_overdue_modality_14_obj: risk_obj["total_risk"]["total_responsability"]["interfinancial_transfers"]["credit_overdue_modality_14"],
    coobligation_sum: risk_obj["total_risk"]["total_responsability"]["coobligation"]["sum"],
    assumed_coobligation_sum: risk_obj["total_risk"]["total_responsability"]["coobligation"]["assumed_coobligation"],
    warrant_obj: risk_obj["total_risk"]["total_responsability"]["coobligation"]["warrant"],
    credit_to_be_released_modality_15_obj: risk_obj["total_risk"]["total_responsability"]["coobligation"]["warrant"]["credit_to_be_released_modality_15"],
    credit_to_be_expired_modality_15_obj: risk_obj["total_risk"]["total_responsability"]["coobligation"]["warrant"]["credit_to_be_expired_modality_15"],
    credit_to_be_overdue_modality_15_obj: risk_obj["total_risk"]["total_responsability"]["coobligation"]["warrant"]["credit_to_be_overdue_modality_15"],
    credit_to_be_released_modality_01_to_13_obj: risk_obj["total_risk"]["credit_to_be_released_modality_01_to_13"],
    credit_limit_modality_19_obj: risk_obj["total_risk"]["credit_limit_modality_19"],
    indirect_risk_sum: risk_obj["total_risk"]["indirect_risk"],
  }
  return mappedRiskCalculationTree
}

export const getTotalBRLandForeignCurrencyObj = (mapped_risk_obj, mapped_risk_obj_foreign_currency) => {
  const total_risk = mapped_risk_obj["total_risk_sum"] + mapped_risk_obj_foreign_currency["total_risk_sum"]
  const total_responsability_sum = mapped_risk_obj["total_responsability_sum"] + mapped_risk_obj_foreign_currency["total_responsability_sum"]
  const loan_portfolio_sum = mapped_risk_obj["loan_portfolio_sum"] + mapped_risk_obj_foreign_currency["loan_portfolio_sum"]
  const credit_to_be_expired_modality_01_to_13_sum = mapped_risk_obj["credit_to_be_expired_modality_01_to_13_obj"]["sum"] + mapped_risk_obj_foreign_currency["credit_to_be_expired_modality_01_to_13_obj"]["sum"]
  const credit_overdue_modality_01_to_13_sum = mapped_risk_obj["credit_overdue_modality_01_to_13_obj"]["sum"] + mapped_risk_obj_foreign_currency["credit_overdue_modality_01_to_13_obj"]["sum"]
  const score_to_be_expired = (credit_to_be_expired_modality_01_to_13_sum / loan_portfolio_sum) * 100
  const score_overdue = (credit_overdue_modality_01_to_13_sum / loan_portfolio_sum) * 100

  return {
    total_risk: total_risk,
    total_responsability_sum: total_responsability_sum,
    loan_portfolio_sum: loan_portfolio_sum,
    credit_to_be_expired_modality_01_to_13_sum: credit_to_be_expired_modality_01_to_13_sum,
    credit_overdue_modality_01_to_13_sum: credit_overdue_modality_01_to_13_sum,
    score_to_be_expired: score_to_be_expired,
    score_overdue: score_overdue,
  }
}

export const getMappedDatasetObj = (scr_data_list) => {
  let dataset_labels = []
  let dataset_to_be_expired = []
  let dataset_overdue = []
  let dataset_total_risk_sum = []
  let dataset_total_responsability_sum = []
  let dataset_total = []

  for (let scr_data of scr_data_list) {
    let { reference_date } = scr_data
    let historic_mapped_risk_obj = getMapRiskCalculationTreeObj(scr_data.risk_calculation_tree)
    let historic_mapped_risk_obj_foreign_currency = getMapRiskCalculationTreeObj(scr_data.risk_calculation_tree_foreign_currency)
    let historic_mapped_total_risk_obj = getTotalBRLandForeignCurrencyObj(historic_mapped_risk_obj, historic_mapped_risk_obj_foreign_currency)
    let historic_credit_to_be_expired_modality_01_to_13_sum_formatted = historic_mapped_total_risk_obj["credit_to_be_expired_modality_01_to_13_sum"] / 100
    let historic_credit_overdue_modality_01_to_13_sum_formatted = historic_mapped_total_risk_obj["credit_overdue_modality_01_to_13_sum"] / 100
    let historic_total_risk_sum = historic_mapped_total_risk_obj["total_risk"] / 100
    let historic_total_responsability_sum = historic_mapped_total_risk_obj["total_responsability_sum"] / 100
    let historic_total_responsability_per_total_risk = historic_total_responsability_sum / historic_total_risk_sum

    dataset_labels.unshift(reference_date)
    dataset_to_be_expired.unshift(historic_credit_to_be_expired_modality_01_to_13_sum_formatted)
    dataset_overdue.unshift(historic_credit_overdue_modality_01_to_13_sum_formatted)
    dataset_total_risk_sum.unshift(historic_total_risk_sum)
    dataset_total_responsability_sum.unshift(historic_total_responsability_sum)
    dataset_total.unshift(Number((historic_total_responsability_per_total_risk * 100).toFixed(2))
    )
  }

  return {
    dataset_labels: dataset_labels,
    dataset_to_be_expired: dataset_to_be_expired,
    dataset_overdue: dataset_overdue,
    dataset_total_risk_sum: dataset_total_risk_sum,
    dataset_total_responsability_sum: dataset_total_responsability_sum,
    dataset_total: dataset_total,
  }
}

export const handleHTML2Img = (divId, setMethod) => {
  const html2Img = document.getElementById(divId)
  html2canvas(html2Img)
    .then((result) => {
      const imgData = result.toDataURL("image/jpg", 0.3)
      setMethod(imgData)
    })
    .catch((error) => console.error(error))
}

export const getPDFHeader = (pdf_title,backgroundColor) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        WebkitPrintColorAdjust: "exact",
        height: "100px",
        overflow: "hidden",
        clear: "both",
        background: backgroundColor,
      }}
    >
      <span
        style={{
          display: "flex",
          color: "white",
          margin: "15px 45px",
          fontSize: "20px",
          fontWeight: "600",
          lineHeight: "30px",
          fontFamily: "Open Sans, sans-serif",
        }}
      >
        {pdf_title}
      </span>
      <img
        style={{
          display: "flex",
          margin: "15px 45px",
          width: "15%",
          height: "auto",
        }}
        src={logoQI}
        alt="logo QI"
      />
    </div>
  )
}

export const getAnnualInterestRate = (monthly_interest_rate) =>
  Number(((Math.pow(monthly_interest_rate/ 100 + 1, 12) - 1) * 100).toFixed(2))

export const scr_table_obj_translation = {
  credit_to_be_expired: [
    "up_to_30_days",
    "between_31_and_60_days",
    "between_61_and_90_days",
    "between_91_and_180_days",
    "between_181_and_360_days",
    "between_361_and_720_days",
    "between_721_and_1080_days",
    "between_1081_and_1440_days",
    "between_1441_and_1800_days",
    "between_1801_and_5400_days",
    "over_5400_days",
    "with_indefinite_term"
  ],
  credit_overdue:[
    "up_to_14_days",
    "between_15_and_30_days",
    "between_31_and_60_days",
    "between_61_and_90_days",
    "between_91_and_120_days",
    'between_121_and_150_days',
    'between_151_and_180_days',
    'between_181_and_240_days',
    'between_241_and_300_days',
    'between_301_and_360_days',
    'between_361_and_540_days',
    'over_540_days'
  ],
  financial_loss: [
    "up_to_12_months", 
    'between_12_and_48_months', 
    'between_over_48_months', 
  ],
  credit_to_be_released:[ 
    'up_to_360_days',
    'over_360_days'
    ],
  credit_limit: [
    'with_maturity_up_to_360_days',
    'with_maturity_over_360_days'
  ],
}

export const scr_modalities_translation = {
  "01": "advances_to_depositors",
  "02": "lending",
  "03": "discounted_securities_discounted_credit_rights",
  "04":	"financing",
  "05":	"export_financing",
  "06":	"import_financing",
  "07":	"intervention_financing",
  "08":	"rural_agro_industrial_financing",
  "09":	"real_estate_financing",
  "10":	"bonds_securities_financing",
  "11":	"infrastructure_and_development_financing",
  "12": "leasing_operations",
  "13":	"other_credits",
  "14":	"interbank_onlending_interbank_relations",
  "15":	"co_obligations",
  "18":	"credit_titles",
  "19":	"limit",
  "20":	"risk_retention"
}

export const scr_submodalities_translation = {
  "01": {
    "01": "advances_to_depositors",
  },
  "02": {
    "01": "overdraft_and_guarantied_overdraft_account",
    "02": "personal_credits_with_payroll_debit_loans",
    "03": "personal_credits_without_payroll_debit_loans",
    "04": "credit_card_revolving_credit",
    "05": "working_capital_with_maturity_of_less_than_30_days",
    "06": "working_capital_with_maturity_of_30_days_or_more",
    "07": "vendor",
    "08": "compror",
    "09": "advance_payment_of_budget_revenue",
    "10": "credit_card_purchase_installment_invoice_or_withdrawal_financed_by_card_issuer_institution",
    "11": "home_equity",
    "12": "microcredit",
    "13": "overdraft",
    "14": "guarantied_overdraft_account",
    "15": "working_capital_with_maturity_up_to_365_days",
    "16": "working_capital_with_maturity_over_365_days",
    "17": "working_capital_revolving_credit",
    "18": "credit_card_not_migrated",
    "50": "acquired_financial_receivables",
    "90": "project_financing",
    "99": "other_loans",
  },
  "03": {
    "01": "trade_bills_discounted",
    "02": "checks_discounted",
    "03": "credit_card_invoices_discounted",
    "98": "other_credit_right_discounted",
    "99": "other_securities_discounted",
  },
  "04": {
    "01": "goods_acquisitions_motor_vehicles",
    "02": "other_goods_acquisitions",
    "03": "microcredit",
    "04": "vendor",
    "05": "compror",
    "06": "credit_card_purchase_or_installment_invoice_financed_by_card_issuer_institution",
    "07": "goods_acquisitions_motor_vehicles_above_2_tonnes",
    "40": "agroindustry_financing",
    "50": "acquired_financial_receivables",
    "90": "project_financing",
    "99": "other_financing",
  },
  "05": {
    "01": "export_financing",
    "02": "advances_on_exchange_contracts",
    "03": "advance_on_delivered_exchange_operations",
    "04": "credit_from_export_export_contracts",
    "90": "project_financing",
    "99": "other_export_financing",
  },
  "06": {
    "01": "import_financing",
    "90": "project_financing",
  },
  "07": {
    "01": "goods_acquisitions_with_intervention_motor_vehicles",
    "02": "other_goods_acquisitions_with_intervention",
    "90": "project_financing",
    "99": "other_financing_with_intervention",
  },
  "08": {
    "01": "costing_and_pre_costing",
    "02": "agrobusiness_financing_investment_and_working_capital",
    "03": "marketing_and_pre_marketing",
    "04": "industrialization",
    "90": "project_financing",
  },
  "09": {
    "01": "housing_financing_SFH",
    "02": "housing_financing_except_SFH",
    "03": "real_state_enterprise_except_housing",
    "90": "project_financing",
  },
  10: {
    "01": "securities_and_stocks_financing",
  },
  11: {
    "01": "infrastructure_and_development_financing",
    "90": "project_financing",
  },
  12: {
    "01": "finance_lease_except_vehicles_and_housing",
    "02": "housing_finance_lease",
    "03": "sublease",
    "05": "operating_lease",
    "06": "finance_lease_of_vehicles",
    "07": "finance_lease_of_vehicles_over_2_tons",
    "90": "project_financing",
  },
  13: {
    "01": "honored_bails_and_guarantees",
    "02": "securities_and_goods_debtors",
    "03": "securities_credit_receivables",
    "04": "credit_card_purchase_in_cash_and_in_installments_shopkeeper",
    "50": "acquired_financial_receivables",
    "90": "project_financing",
    "99": "others_with_credit_characteristics",
  },
  14: {
    "01": "interbank_onlendings",
    "02": "payment_arrangement_receivables",
    "03": "other_receivables_related_to_payment_transactions",
  },
  15: {
    "01": "beneficiaries_of_guarantees_provided_for_operations_with_financial_companies",
    "02": "beneficiaries_of_guarantees_provided_for_operations_with_other_people",
    "03": "beneficiaries_of_guarantees_provided_for_constitutional_funds",
    "04": "beneficiaries_of_guarantees_provided_for_participation_in_the_bidding_process",
    "05": "import_letter_of_credit",
    11: "coobligation_assumed_in_assignment_with_coobligation_for_a_SFN_member",
    12: "coobligation_assumed_in_assignment_with_coobligation_for_a_non_SFN_member",
    13: "other_coobligations_beneficiaries",
    90: "project_financing",
    99: "other_guarantees_beneficiaries",
  },
  18: {
    "01": "rural_product_bill",
    "02": "export_note",
    "03": "debentures",
    "04": "commercial_notes",
    "99": "others",
  },
  19: {
    "01": "global_contracted_and_unused_limit",
    "02": "overdraft",
    "03": "guarantied_overdraft_account",
    "04": "credit_card",
    "05": "working_capital",
    "06": "personal_credit",
    "07": "vendor",
    "08": "compror",
    "09": "discounts",
    "10": "good_acquisitions",
  },
  20: {
    "01": "risk_retention_assumed_by_fund_shares_acquisition",
    "02": "risk_retention_assumed_by_acquisition_of_instruments_backed_by_credit_operations",
  },
}

export const getScoreInterpretation = (score, legal_nature) => {
  if (legal_nature === "natural_person"){
    if (score < 100) return "serasa_score_natural_person_up_to_100_interpretation"
    else if (score < 200) return "serasa_score_natural_person_between_101_and_200_interpretation"
    else if (score < 300) return "serasa_score_natural_person_between_201_and_300_interpretation"
    else if (score < 400) return "serasa_score_natural_person_between_301_and_400_interpretation"
    else if (score < 500) return "serasa_score_natural_person_between_401_and_500_interpretation"
    else if (score < 600) return "serasa_score_natural_person_between_501_and_600_interpretation"
    else if (score < 700) return "serasa_score_natural_person_between_601_and_700_interpretation"
    else if (score < 800) return "serasa_score_natural_person_between_701_and_800_interpretation"
    else if (score < 900) return "serasa_score_natural_person_between_801_and_900_interpretation"
    else if (score < 1000) return "serasa_score_natural_person_greater_than_901_interpretation"
  }
else{
    if (score < 50) return 'serasa_score_legal_person_up_to_50_interpretation'
    else if (score < 100) return 'serasa_score_legal_person_between_51_and_100_interpretation'
    else if (score < 150) return 'serasa_score_legal_person_between_101_and_150_interpretation'
    else if (score < 200) return 'serasa_score_legal_person_between_151_and_200_interpretation'
    else if (score < 250) return 'serasa_score_legal_person_between_201_and_250_interpretation'
    else if (score < 300) return 'serasa_score_legal_person_between_251_and_300_interpretation'
    else if (score < 350) return 'serasa_score_legal_person_between_301_and_350_interpretation'
    else if (score < 400) return 'serasa_score_legal_person_between_351_and_400_interpretation'
    else if (score < 450) return 'serasa_score_legal_person_between_401_and_450_interpretation'
    else if (score < 500) return 'serasa_score_legal_person_between_451_and_500_interpretation'
    else if (score < 550) return 'serasa_score_legal_person_between_501_and_550_interpretation'
    else if (score < 600) return 'serasa_score_legal_person_between_551_and_600_interpretation'
    else if (score < 650) return 'serasa_score_legal_person_between_601_and_650_interpretation'
    else if (score < 700) return 'serasa_score_legal_person_between_651_and_700_interpretation'
    else if (score < 750) return 'serasa_score_legal_person_between_701_and_750_interpretation'
    else if (score < 800) return 'serasa_score_legal_person_between_751_and_800_interpretation'
    else if (score < 850) return 'serasa_score_legal_person_between_801_and_850_interpretation'
    else if (score < 900) return 'serasa_score_legal_person_between_851_and_900_interpretation'
    else if (score < 950) return 'serasa_score_legal_person_between_901_and_950_interpretation'
    else return 'serasa_score_legal_person_greater_than_951_interpretation'
  }
}

export const nameMask = value => {
  return value.replace(/[^a-zA-Z áÁàÀíÍóòéÉúÚ]*$/, '').replace('  ', ' ');
}

export const cpfMask = value => {
  return value.slice(0,14)
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
}

export const cnpjMask = value => {
  return value.slice(0,18)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
}

export const dateMask = value => {
  let v = value.replace(/\D/g,'').slice(0, 8);
  if (v.length >= 5) {
    return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
  }
  else if (v.length >= 3) {
    return `${v.slice(0,2)}/${v.slice(2)}`;
  }
  return v
}

export const percentageMask = value => {
  let v = String(+(value.substring(0,8)))
  if (v.length === 1)
    return "0,0" + v
  else if (v.length === 2)
    return "0," + v
  return v
  .replace(/(\d)(\d{5})$/,"$1.$2")
  .replace(/(\d)(\d{2})$/,"$1,$2")
};

export const formatToBrMoney = (value) => {
  let v = String(+(value.substring(0,17)))
  if (v.length === 1)
    return "R$ 0,0" + v
  else if (v.length === 2)
    return "R$ 0," + v
  return "R$ " + v
    .replace(/(\d)(\d{14})$/,"$1.$2")
    .replace(/(\d)(\d{11})$/,"$1.$2")
    .replace(/(\d)(\d{8})$/,"$1.$2")
    .replace(/(\d)(\d{5})$/,"$1.$2")
    .replace(/(\d)(\d{2})$/,"$1,$2")
};

export const justNumbers = (value) => {
  return value.replace(/\D/g, '')
}

export const isNaturalDocumentNumber = (value) => {
  if (justNumbers(value).length < 11) return false
  else return true
}

export const isLegalDocumentNumber = (value) => {
  if (justNumbers(value).length < 14) return false
  else return true
}

export const isNaturalName = (value) => (value.split(" ").length > 1) && (value.length > 4);

export const isLegalname = (value) => value.split(" ").length > 0 && (value.length > 2);

export const isEmail = (value) => value.includes("@");

export const isDate = (value) => {
  let day = Number(justNumbers(value).substring(0, 2))
  let month = Number(justNumbers(value).substring(2, 4))
  let year = Number(justNumbers(value).substring(4, 8))
  if (value.length<9) return false
  else if (day<1||day>31) return false
  else if (month<1||month>12) return false
  else if (year<1500) return false
  else return true
}

export const formatToPhone = (value) => {
  let v = String(value.substring(0,11))
  if (v.length===0) {
    return ""
  }
  if (v.length < 3) {
    return "("+v
  }
  else if (v.length < 8) {
    return "(" + v.substring(0,2) + ")" + v.substring(2,v.length)
  }
  else {
    return "(" + v.substring(0,2) + ")" + v.substring(2,7) + "-" + v.substring(7,v.length)
  }
}

export const isPhone = (value) => {
  if (justNumbers(value).length < 11) return false
  else return true
}

export const authorization_type_enumList = ['sms', 'whatsapp', 'email']

export const valueIsNotZero = (value) => Number(justNumbers(value)) > 0;
export const percentageIsNotZero = (value) => Number(justNumbers(value)) > 0;
export const isNotZero = (value) => Number(justNumbers(value)) > 0;

export const formFieldsHandler = (person_type, input_settings, company_key) => {

  const formFields = {
    "natural_document_number": false,
    "natural_person_name": false,
    "legal_document_number": false,
    "legal_name": false,
    "scr_consent_data_title":false,
    "signer_document_number": false,
    "signer_name": false,
    "authorization_type": false,
    "upload_PDF_base64": false,
    "client_since": false,
    "monthly_income": false,
    "monthly_revenue": false,
    "credit_type": false,
    "credit_proposal_amount": false,
    "interest_type": false,
    "number_of_installments":false
  };

  if (person_type==="np") {
    formFields["natural_document_number"] = true
  } else if (person_type==="lp") {
    formFields["legal_document_number"] = true
  };

  if (input_settings.use_scr){

    formFields["scr_consent_data_title"]=true

    if (person_type==="np") {
      formFields["natural_person_name"] = true
    } else if (person_type==="lp") {
      formFields["legal_name"] = true
    };

    let manual_external_authorization_company_keys = ["789-789-789", "100-100-100", "2255c314-4dd4-4b8c-bb6a-507fe19b3a49","43f57d03-e736-4593-ad7b-76a7dd8567bf", "28dc9fda-a27a-46be-91e1-20ab94739a29", "b5605939-5c52-4e6d-8ac3-e5dd98daf72b"]

    if (manual_external_authorization_company_keys.some(company_key_item => company_key_item === company_key)) {
      formFields['upload_PDF_base64'] = true
    } else if ( person_type==="np" ) {
      formFields["authorization_type"] = true
    } else if ( person_type==="lp" ) {
      formFields["authorization_type"] = true
      formFields["signer_document_number"] = true
      formFields["signer_name"] = true
    };
  }

  if (input_settings.collect_additional_basic_data.client_since) {
    formFields['client_since'] = true
  };

  if (input_settings.collect_additional_basic_data.client_since) {
    formFields['client_since'] = true
  };
  
  if (person_type==="np"&&input_settings.collect_additional_basic_data.monthly_income) {
    formFields['monthly_income'] = true
  };
  
  if (person_type==="lp"&&input_settings.collect_additional_basic_data.monthly_revenue) {
    formFields['monthly_revenue'] = true
  };
  
  if (input_settings.collect_financial_data.credit_type) {
    formFields['credit_type'] = true;
  };
  
  if (input_settings.collect_financial_data.credit_proposal_amount) {
    formFields['credit_proposal_amount'] = true;
  };
  
  if (input_settings.collect_financial_data.interest_type) {
    formFields['interest_type'] = true;
  }
  
  if (input_settings.collect_financial_data.number_of_installments) {
    formFields['number_of_installments'] = true;
  }
  return formFields
}

export const buildSCRSettings = (companiesList, business_group_key) => {
  let scr_settings = []
  if (business_group_key) {
    companiesList.companies.map((company) => (
      scr_settings.push(
        {
          "company_key": company.company_key,
          "scr_settings": {...company.scr_settings}
        }
      )
    ))
  } else {
    scr_settings.push(
      {
        "company_key": companiesList.company_key,
        "scr_settings": {...companiesList.scr_settings}
      }
    )
  }
  return scr_settings
}
function formatPostalCode(postalCode) {
  if (postalCode.length === 7) {
    return "0"  + postalCode.slice(0, 4) + "-" + postalCode.slice(4);
  } else if (postalCode.length === 8) {
    return postalCode.slice(0, 5) + "-" + postalCode.slice(5);
  } else {
    return postalCode;
  }
}
export const formatAddress = (address, nullCase) => {
  if (address == null) return nullCase
  let street = titleCase(address.street, "")
  let neighborhood = titleCase(address.neighborhood, "")
  let city = titleCase(address.city, "")
  let state = address.state ? address.state : ""
  let postalCode = address.postal_code? address.postal_code  : ""
  let zipCode = address.zip_code? address.zip_code  : ""
  let formattedPostalCode = formatPostalCode(postalCode);
  let formattedZipCode = formatPostalCode(zipCode);
  let addressString = ""
  if (street) addressString += street 
  if (neighborhood) addressString += " - " + neighborhood
  if (city) addressString += ", " + city
  if (state) addressString += " - " + state
  if (zipCode) addressString += ", " + formattedZipCode
  else if (postalCode) addressString += ", " + formattedPostalCode
  return (addressString)
}

function formatPhoneNumber(phoneNumber) {
  if (phoneNumber.length === 7) {
    return phoneNumber.slice(0, 4) + "-" + phoneNumber.slice(4);
  } else if (phoneNumber.length === 8) {
    return phoneNumber.slice(0, 4) + "-" + phoneNumber.slice(4);
  } else {
    return phoneNumber; 
  }
}

export const formatPhoneObject = (phone, nullCase) => {
  if (phone == null) return nullCase;
  let number = phone.number.toString();;
  let area_code = phone.area_code;
  let country_code = phone.country_code;

  let fomatted_phone = formatPhoneNumber(number);

  let phoneString = "+"+country_code+" "+area_code+" "+fomatted_phone;
  
  return (phoneString)
}

export function checkNested(obj /*, level1, level2, ... levelN*/) {
  if (obj == null) return false
  var args = Array.prototype.slice.call(arguments, 1)

  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false
    }
    obj = obj[args[i]]
  }
  return true
}

export const clientAge = (birthdate, nullCase = null) => {
  if (birthdate == null) return nullCase
  return (
    moment(birthdate).format("DD/MM/YYYY") +
    " (" +
    moment().diff(moment(birthdate), "years") +
    " anos)"
  )
}

export const formatEmail = (emailObj, nullCase) => {
  if (emailObj == null) return nullCase
  return emailObj.email == null ? "" : emailObj.email
}

export const formatListOfObjects = (objects, identifier, t) => {
  let formatFunction
  switch (identifier) {
    case "addresses":
      formatFunction = formatAddress
      break
    case "phones":
      formatFunction = formatPhone
      break
    case "emails":
      formatFunction = formatEmail
      break
    default:
      formatFunction = (value) => (value)
      break
  }
  let bureauInfo = []
  objects.forEach((object)=>{
    switch (identifier) {
      case "phones":
        bureauInfo.push(`${t(object.type)} ${formatFunction(object)}`)
        break
      default:
        bureauInfo.push(formatFunction(object))
        break
    }
  })
  return bureauInfo.join("; ")
}

export const formatPhone = (phoneObj, nullCase) => {
  if (phoneObj == null) return nullCase

  let ddiString =
    phoneObj.internation_dial_code == null
      ? ""
      : phoneObj.internation_dial_code + " "
  let dddString =
    phoneObj.area_code == null ? "" : "(" + phoneObj.area_code + ") "
  let numberString = phoneObj.number == null ? "" : phoneObj.number

  return ddiString + dddString + numberString
}
export function validateCPF(cpf) {
  if (typeof cpf !== "string") return false
  cpf = cpf.replace(/[^\d]+/g,'')

  if (
      !cpf ||
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
  ) return false

  var sum = 0
  var remainder
  for (var count = 1; count <= 9; count++) 
      sum = sum + parseInt(cpf.substring(count-1, count)) * (11 - count)
  remainder = (sum * 10) % 11
  if ((remainder === 10) || (remainder === 11))  remainder = 0
  if (remainder !== parseInt(cpf.substring(9, 10))) return false

  sum = 0
  for (count = 1; count <= 10; count++) 
      sum = sum + parseInt(cpf.substring(count-1, count)) * (12 - count)
  remainder = (sum * 10) % 11
  if ((remainder === 10) || (remainder === 11))  remainder = 0
  if (remainder !== parseInt(cpf.substring(10, 11))) return false

  return true
}

export function validateCNPJ(cnpj) {
  if (typeof cnpj !== "string") return false
  cnpj = cnpj.replace(/[^\d]+/g, "")
  if (cnpj === "") return false
  if (cnpj.length !== 14) return false

  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado.toString() !== digitos.charAt(0)) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado.toString() !== digitos.charAt(1)) return false

  return true
}