import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #cccc;
    border-radius: 10px;
    padding: 21px;
    display:flex;
    flex-grow: 1;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const Title = styled.p`
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    color: #0b1f82;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Sport = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 16px;
`;

export const SportName = styled.p`
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    color: #0b1f82;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 14px 0;
    padding: 0;
`;

export const Badge = styled.div`
    padding: 4px 8px;
    margin: 0 auto;
    border-radius: 4px;
    width: fit-content;
    font-weight: 600;
    color: ${(props) => props.positive ? '#00c41d' : '#a60505'};
    background-color: ${(props) => props.positive ? '#6dd67d55' : '#ff828255'};
`;