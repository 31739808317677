import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PageWrapper = styled.div`
    width: 640px;
    padding: 32px 32px 64px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 16px;
    gap: 32px;
    justify-content: space-between;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
`;

export const Title = styled.p`
    margin: 0;
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: bold;
    color: #202020;

`;

export const Description = styled.p`
    margin: 16px 0 0 0;
    font-size: 14px;
    color: #323232;
    text-align: justify;
`;

export const CommandText = styled.p`
    padding: 4px 8px;
    background-color: #eaeaea;
    border: 1px solid #bababa;
    font-family: monospace;
    font-weight: bold;
    font-size: 18px;
    color: #202020;
    border-radius: 4px;
    user-select: none;
    text-align: center;
`;


export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 32px;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
    height: 30px;
`;

export const FilledButton = styled.div`
    color: white;
    background-color: #0B1F82;
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: fit-content;
    user-select: none;
    font-weight: bold;
    transition: 0.5s;
    height: 30px;
`;

export const OutlinedButton = styled.div`
    color: ${(props) => props.enabled ? '#CE2C31' : "#777777"};
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.enabled ? '#CE2C31' : "#777777"};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => props.enabled ? props.loading ? 'progress' : 'pointer' : 'not-allowed'};
    height: fit-content;
    user-select: none;
    font-weight: bold;
`;

export const ValidationTextField  = styled(TextField)`
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: ${(props) => props.correct ? "#30A46C" : "#CE2C31"};
        }
    }
`;