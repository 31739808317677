import React from "react";
import { getCardIcon, getTableRowClass } from "../../../utils/utils"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import Paper from "@material-ui/core/Paper";
import * as S from './styles';
import { TableBody, TableHead, TableRow, Table, TableCell } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
    },
    tableText: {
      fontFamily: "Open Sans",
      fontWeight: 'normal',
      fontSize: "12px",
      lineHeight: "16px",
      color: "#141414",
      textAlign: "center",
      margin: "10px",
      padding: "6px",
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "6px",
    },
    even: {
      background: "#F3F3F3",
    },
    odd: {
      background: "#FFFFFF",
    },
    circle: {
          height:'11px',
          borderRadius:'50%',
      width:'11px',
      margin: "auto 3px"
    },
    seeMoreLink: {
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "19px",
      color: "#0b1f82",
      cursor: "pointer",
      textAlign: "right",
      margin: "auto",
      "&:hover": {
        textDecoration: "underline",
      }, 
    }
  }));

function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function SportsExposureCard(props) {
    const classes = useStyles();
    useTheme();

    const { sports_exposure } = props
    const sports = ((sports_exposure || {}).data || {}).sports
    const { t } = useTranslation();
    
    if (!sports) {
        return (
            <S.Container>
                <S.Header>
                    <S.Title>Exposições Esportivas</S.Title>
                    {getCardIcon((sports_exposure || {}).flag)}
                </S.Header>
            </S.Container>
        );
    }

    return (
        <S.Container>
            <S.Header>
                <S.Title>Exposições Esportivas</S.Title>
                {getCardIcon((sports_exposure || {}).flag)}
            </S.Header> 
            <S.Content>
                {sports.map((sport, index) => (
                    <S.Sport key={'SPORT-' + index}>
                        <S.SportName>{t(sport.name ?? "-")} - {t(sport.region ?? "-")}</S.SportName>
                        <Paper className={classes.tableStyle}>
                            <Table style={{padding: '8px'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeader}>Documento exposto</TableCell>
                                        <TableCell className={classes.tableHeader}>Nível</TableCell>
                                        <TableCell className={classes.tableHeader}>Função</TableCell>
                                        <TableCell className={classes.tableHeader}>Instituição</TableCell>
                                        <TableCell className={classes.tableHeader}>Atividade</TableCell>
                                        <TableCell className={classes.tableHeader}>Status da exposição</TableCell>
                                        <TableCell className={classes.tableHeader}>Início</TableCell>
                                        <TableCell className={classes.tableHeader}>Fim</TableCell>
                                        <TableCell className={classes.tableHeader}>Fonte</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(sport.exposures || []).map((exposure, index) => (
                                    <TableRow key={'EXPOSURE-' + index} className={getTableRowClass(index)}>
                                        <TableCell className={classes.tableText}>{formatCPF(exposure.exposed_document_number ?? "-")}</TableCell>
                                        <TableCell className={classes.tableText}>{t(exposure.level ?? "-")}</TableCell>
                                        <TableCell className={classes.tableText}>{t(exposure.role ?? "-")}</TableCell>
                                        <TableCell className={classes.tableText}>{capitalize(exposure?.institution?.name ?? "-")}</TableCell>
                                        <TableCell className={classes.tableText}>{capitalize(exposure?.institution?.activity ?? "-")}</TableCell>
                                        <TableCell className={classes.tableText}><S.Badge positive={exposure.active}>{exposure.active ? 'Ativa' : 'Inativa'}</S.Badge></TableCell>
                                        <TableCell className={classes.tableText}>{exposure.start_date ? moment(exposure.start_date).format("DD/MM/YYYY") : "-"}</TableCell>
                                        <TableCell className={classes.tableText}>{exposure.end_date ? moment(exposure.end_date).format("DD/MM/YYYY") : "-"}</TableCell>
                                        <TableCell className={classes.tableText}>{exposure.source ?? "-"}</TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </S.Sport>
                ))}
            </S.Content> 
        </S.Container>
    );
}

export default SportsExposureCard;