import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
// import ContestedDialog from "./ContestedDialog/ContestedDialog";
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AuthContext from "../../../../context/auth-context"
import {setAllowChanges, replaceAll, formatAsMoney, upgradeStatusTranslation} from "../../utils/utils"
import Tooltip from '@material-ui/core/Tooltip'
import ObservationTextField from "../../../../components/common/ObservationTextField";

const theme = createMuiTheme({
    overrides: { MuiInputBase: { root:{
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",

    }}},
    palette:{
        primary: {
            main:"#0b1f82"
        },
    }
  });

function Observation(props) {

    const { totalSeconds, upgrade_status, showUpgradeStatus, fraud_status } = props

    let roles = useContext(AuthContext).user_data.roles

    let allowChanges = setAllowChanges(fraud_status, roles)

    const [openDialog, setOpenDialog] = useState(false)
    const [isApproved, setIsApproved] = useState(null)
    // const [isContested, setIsContested] = useState(false)

    const [disableChange, setDisableChange] = useState(false)

    useEffect(()=> {
        if (totalSeconds != null && totalSeconds <= 0 ){
            setDisableChange(true)
        }
    },[totalSeconds])

    const handleApprovedOpenClick = () => {
        setIsApproved(true)
        setOpenDialog(true)
    };

    const handleReprovedOpenClick = () => {
        setIsApproved(false)
        setOpenDialog(true)
    };

    // const handleContestedOpenClick = () => {
    //     setIsContested(true);
    // };

    // const handleContestedDialogClose = () => {
    //     setIsContested(false);
    // }

    const handleDialogClose = () => {
        setOpenDialog(false)
        setTimeout(function (){setIsApproved(null)}, 300)
    };

    let observation = null
    let analyst = ""
    let increaseString = ""

    if (!allowChanges){
        let last_event = props.rental_agreement.fraud_status_events == null || props.rental_agreement.fraud_status_events.length === 0 
                         ? null 
                         : props.rental_agreement.fraud_status_events[props.rental_agreement.fraud_status_events.length-1]
        if (last_event){
            observation = last_event.observation
            let string = last_event.analyst_name == null ? null : "Análise feita por %name, %email no dia %day às %time.\n"
            increaseString = last_event.pre_authorization_amount ? "Pré Autorização majorada a um valor de " + formatAsMoney(last_event.pre_authorization_amount, "") + ".\n" : ""
            let mapObj = {
                "%name": last_event.analyst_name,
                "%email": last_event.analyst_email,
                "%day": moment(last_event.event_date).format("DD/MM/YYYY"),
                "%time": moment(last_event.event_date).format("HH:mm"),
            }
            analyst = string == null ? "" : replaceAll(string, mapObj)
        }
    }

    const [upgradeStatusInput, setUpgradeStatusInput] = useState("")

    const handleUpgradeStatusChange = (event) => {
        setUpgradeStatusInput(event.target.value)
    }

    const [observationBox, setObservationBox] = useState(allowChanges 
        ? disableChange 
            ? "Aprovado automaticamente devido ao esgotamento do tempo"  
            : "" 
            : observation == null 
                ? (analyst + increaseString) 
                : (analyst + increaseString + observation))

    const [reason, setReason] = useState('select');
    const updateReason = (newReason) => {
        if (newReason === 'COMPROT') {
            setObservationBox('Reprovado por COMPROT: ');
        } else if (newReason === 'profile_analysis_alerts') {
            setObservationBox('Reprovado por análise de perfil (combo alertas): ');
        } else if (newReason === 'profile_analysis_reserved') {
            setObservationBox('Reprovado por análise de perfil (grupo reservado): ');
        } else if (newReason === 'quiz_high_risk') {
            setObservationBox('Reprovado pelo Quiz de Alto Risco: ');
        } else if (newReason === 'cnh') {
            setObservationBox('CNH ausente/ilegível/vencida: ');
        } else if (newReason === 'suspicious_client') {
            setObservationBox('Cliente Suspeito: ');
        } else if (newReason === 'system_failure') {
            setObservationBox('Análise sem informações/ falha do sistema: ');
        } else {
            setObservationBox('');
        }
        setReason(newReason)
    }

    return (
        
        <div className="analysisCard">
            <div style={{marginBottom: "10px"}}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                    <div className={["blueText", "subtitleSize"].join(" ")}>Observação</div>
                    <div style={(allowChanges && !disableChange) ? {} : {display: "none"}}>
                        <div className={["blueText", "normalMediumSize"].join(" ")} style={{marginBottom: 8}}>Motivos de análise de reprova</div>
                        <Select style={reason === 'select' ? {paddingLeft: "8px", minWidth: "100%", color: "#777"} : {paddingLeft: "8px", minWidth: "100%"}} 
                            labelId="reason-selection" id="reason-selection-dropdown" value={reason} label="Razão" onChange={(e) => updateReason(e.target.value)}>
                            <MenuItem value="select" style={{color: "#777"}}>Selecionar</MenuItem>
                            <MenuItem value="COMPROT">COMPROT</MenuItem>
                            <MenuItem value="profile_analysis_alerts">Análise de perfil (combo alertas)</MenuItem>
                            <MenuItem value="profile_analysis_reserved">Análise de perfil (grupo reservado)</MenuItem>
                            <MenuItem value="quiz_high_risk">Quiz alto risco</MenuItem>
                            <MenuItem value="cnh">CNH ausente/ilegível/vencida</MenuItem>
                            <MenuItem value="suspicious_client">Cliente suspeito (alerta loja)</MenuItem>
                            <MenuItem value="system_failure">Análise sem informações/ falha do sistema</MenuItem>
                            <MenuItem value="others">Outros</MenuItem>
                        </Select>
                    </div>
                </div>
                <ObservationTextField content={observationBox} setContent={setObservationBox} service="car_rental" disable={!(allowChanges && !disableChange)}/>
            </div>
            {showUpgradeStatus 
                ? !allowChanges
                    ? <div style={{marginBottom: "10px"}}> 
                            <div className={["blueText", "subtitleSize"].join(" ")}>Decisão Upgrade</div>
                            <ThemeProvider theme={theme}>
                            <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    disabled= {true}
                                    onChange={handleUpgradeStatusChange}
                                    defaultValue={disableChange && upgrade_status ==="in_manual_analysis"
                                                        ? "Aprovado automaticamente devido ao esgotamento do tempo" 
                                                        : upgradeStatusTranslation(upgrade_status) + "."}
                                />
                            </ThemeProvider>
                        </div>
                    : disableChange 
                        ? <div style={{marginBottom: "10px"}}>
                            <div className={["blueText", "subtitleSize"].join(" ")}>Decisão Upgrade</div>
                            <ThemeProvider theme={theme}>
                            <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    disabled= {true}
                                    onChange={handleUpgradeStatusChange}
                                    defaultValue={"Aprovado automaticamente devido ao esgotamento do tempo"}
                                />
                            </ThemeProvider>
                        </div>
                        : (upgrade_status === "automatically_approved" || upgrade_status === "automatically_reproved") 
                            ? <div style={{marginBottom: "10px"}}>
                            <div className={["blueText", "subtitleSize"].join(" ")}>Decisão Upgrade</div>
                            <ThemeProvider theme={theme}>
                            <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    disabled= {true}
                                    onChange={handleUpgradeStatusChange}
                                    defaultValue={upgradeStatusTranslation(upgrade_status) + "."}
                                />
                            </ThemeProvider>
                        </div>
                        : <div style={{marginBottom: "10px"}}>
                            <div className={["blueText", "subtitleSize"].join(" ")}>Decisão Upgrade</div>
                                <ThemeProvider theme={theme}>
                                    <Select
                                    className="selectedFilter"
                                    style={{display: "block", paddingLeft: "10px"}}
                                    displayEmpty
                                    onChange={handleUpgradeStatusChange}
                                    value={upgradeStatusInput}
                                    >
                                        <MenuItem value={"manually_approved"}>Aprovar</MenuItem>
                                        <MenuItem value={"manually_reproved"}>Reprovar</MenuItem>
                                    </Select>
                                </ThemeProvider>
                            </div>
                : null}
            {showUpgradeStatus 
            ? (allowChanges && !disableChange)
                ? (!!upgradeStatusInput || upgrade_status === "automatically_approved" || upgrade_status === "automatically_reproved")
                    ? (upgradeStatusInput === "manually_approved") 
                        ? <div style={{display:"flex", marginTop: "10px", justifyContent: "flex-end"}}>
                            <div 
                                className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                                onClick={handleApprovedOpenClick}
                            >
                                APROVAR
                            </div>     
                            <Tooltip title={<p className="tooltipText">{"Não é possível reprovar o Aluguel e aprovar o Upgrade. Para reprovar o Aluguel, selecione Reprovar o Upgrade."}</p>}>
                                <div className={["button", "reproved","disabled", "normalText", "normalMediumSize"].join(" ")}>
                                    REPROVAR
                                </div>    
                            </Tooltip>  
                        <ConfirmationDialog
                            open={openDialog}
                            isApproved={isApproved}
                            data={observationBox}
                            upgradeStatus={upgradeStatusInput}
                            onClose={handleDialogClose}
                            ra={props.rental_agreement}
                        />
                        </div>
                        : <div style={{display:"flex", marginTop: "10px", justifyContent: "flex-end"}}>
                            <div 
                                className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                                onClick={handleApprovedOpenClick}
                            >
                                APROVAR
                            </div>   
                            
                            <div 
                                className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                                onClick={handleReprovedOpenClick}
                            >
                                REPROVAR
                            </div>     
                        <ConfirmationDialog
                            open={openDialog}
                            isApproved={isApproved}
                            data={observationBox}
                            upgradeStatus={upgradeStatusInput}
                            onClose={handleDialogClose}
                            ra={props.rental_agreement}
                        />
                        </div>
                    : <div style={{display:"flex", marginTop: "10px", justifyContent: "flex-end"}}>
                        <div 
                            className={["button", "approved", "disabled", "normalText", "normalMediumSize"].join(" ")} 
                        >
                            APROVAR
                        </div>     
                        <div 
                            className={["button", "reproved","disabled", "normalText", "normalMediumSize"].join(" ")} 
                        >
                            REPROVAR
                        </div>     
                    </div>
                : null
            : (allowChanges && !disableChange)
                ? <div style={{display:"flex", marginTop: "10px", justifyContent: "flex-end"}}>
                    <div 
                        className={["button", "approved", "normalText", "normalMediumSize"].join(" ")} 
                        onClick={reason === 'select' ? handleApprovedOpenClick : () => {}}
                        style={reason === 'select' ? {} : {backgroundColor: "#a8a8a8"}}
                    >
                        APROVAR
                    </div>   
                    
                    <div 
                        className={["button", "reproved", "normalText", "normalMediumSize"].join(" ")} 
                        onClick={reason === 'select' ? () => {} : handleReprovedOpenClick}
                        style={reason === 'select' ? {backgroundColor: "#a8a8a8"}: {}}
                    >
                        REPROVAR
                    </div>
                    {/* <div
                        className={["button", "normalText", "normalMediumSize"].join(" ")}
                        onClick={reason === 'select' ? handleContestedOpenClick : () => {}}
                        style={reason === 'select' ? {backgroundColor: "#fcba03", color: "white"} : {backgroundColor: "#8a8a8a", color: "white"}}
                    >
                        CONTESTAR
                    </div> */}
                <ConfirmationDialog
                    open={openDialog}
                    isApproved={isApproved}
                    data={observationBox}
                    upgradeStatus={upgradeStatusInput}
                    onClose={handleDialogClose}
                    ra={props.rental_agreement}
                />
                {/* <ContestedDialog
                    open={isContested}
                    data={observationBox}
                    onClose={handleContestedDialogClose}
                    ra={props.rental_agreement}
                /> */}
                </div> 
                : null}
        </div>
    )        
}

export default Observation;