import React from "react";
import { useTranslation } from "react-i18next";
import { formatBoavistaData, formatToUpperFirstLetter } from "../../../../utils/utils";
import DataField from "../../../../utils/DataField";


function BoavistaBasicDataCard (props){

  const { data } = props
  
  const { t } = useTranslation()

  let addressList = []
  for (let keyData of Object.keys(data.addresses[0])) {
    if (data.addresses[0][keyData]!==null) {
      if (keyData!=="state") {
        addressList.push(formatToUpperFirstLetter(data.addresses[0][keyData] ?? ''))
      }
      else {
        addressList.push(data.addresses[0][keyData])
      }
    }
  }
  let address = ""
  if (addressList.lenght!==0){
    address = addressList.join(", ")
  }

  let phonesList = []
  for (let phone of data.phones) {
    phonesList.push(`${phone.country_code}(${phone.area_code})${phone.number}`)
  }
  let phones = ""
  if (phonesList.lenght!==0){
    phones = phonesList.join("; ")
  }
  
  const firstColumnData = ["document_number","current_capital","legal_name","registration_date","receita_federal_situation","receita_federal_situation_date","gender","marital_status"]
  const secondColumnData = ["dependents","education_level","fiscal_situation","update_date","voter_number","is_dead","phones","address"]
  const isInColumn = (keyData,columnData) => {
    if (columnData.includes(keyData)) return true
    else return false
  }

  return (
    <div  className="avoidPageBreakInside" style={{padding:"10px"}}>
      <div className="analysisCard" style={{height:"100%"}}>
        <div className={["blueText", "subtitleSize"].join(" ")} style={{marginBottom: "10px"}}>{t("basic_data")}
        </div>
        <div style={{display:"grid", gridTemplateColumns:"50% 50%"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            {Object.keys(data.identification).map((keyData, index)=>(
              isInColumn(keyData,firstColumnData)&&
                <DataField
                    key={index}
                    title={t(keyData)}
                    label={data.identification[keyData] ? formatBoavistaData(keyData,data.identification[keyData]) : " - "}
                    size={"normalMediumSize"}
                    titleNoWrap={false}
                />
            ))}
          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            {Object.keys(data.identification).map((keyData, index)=>(
              isInColumn(keyData,secondColumnData)&&
                <DataField
                  key={index}
                  title={t(keyData)}
                  label={data.identification[keyData] ? formatBoavistaData(keyData,data.identification[keyData]) : " - "}
                  size={"normalMediumSize"}
                  titleNoWrap={false}
                />
            ))}
            {phones&&
              <DataField
                  title={t("phones")}
                  label={phones ? phones : " - "}
                  size={"normalMediumSize"}
                  titleNoWrap={false}
              />}
            {address&&
              <DataField
                  title={t("address")}
                  label={address ? address : " - "}
                  size={"normalMediumSize"}
                  titleNoWrap={false}
              />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoavistaBasicDataCard