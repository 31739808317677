import React, { useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent";
import {decisionReducer} from "../../../../Utils/requestUtils";
import * as S from './styles';

function DeleteListDialog(props) {

    const [deletionLoading, setDeletionLoading] = useState(false);

    const handleClipboard = (e) => {
        e.preventDefault();
    }

    const handleCancel = () => {
        props.handleUpdatePage()
    }
    const handleDelete = () => {
        if (content === commandText && !deletionLoading) {
            setDeletionLoading(true);
            handleConfirm();
        }
    }

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        {isLoading: false, isError: false, finished:false}
    )

    const commandText = props?.list?.name;
    const [content, setContent] = useState('');


    const handleConfirm = () =>{
        dispatchDialogState({type:"send_request_init"})
        let requestHeaders = {headers:{}}
        if (props.user_data.business_group_key) {
            requestHeaders = {headers:{company_key:props.list.company_key}}
        }
        setTimeout(function () {
            axios.delete('/dash/lists/list/' + props.list.list_key,requestHeaders).then(response => {
                dispatchDialogState({type:"send_request_success"})
              }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({type: "send_request_failure_403"})
                else if ((error.response || {}).status === 409) dispatchDialogState({type: "send_request_failure_409"})  
                else dispatchDialogState({type: "send_request_failure"})    
              });
        }, 1000);
    }


    if (dialogState.finished && dialogState.isLoading){
        return (
            <Dialog 
                open={props.open} 
                onClose={props.handleUpdatePage} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Exclusão de Lista</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished){
        let confirmationData = {    
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog 
                open={props.open} 
                onClose={props.handleUpdatePage} 
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Exclusão de Lista</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={props.handleUpdatePage} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }
    else {
        return (
            <Dialog 
            open={props.open} 
            onClose={props.onClose} 
            keepMounted={false}
            >
                <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>Exclusão de Lista</DialogTitle>
                <DialogContent>
                    <S.Description>
                        <b>Aviso: </b>Atente-se para excluir somente listas que <b>não estão mais sendo utilizadas</b> pelo motor de regras. Ao excluir uma lista que está configurada em alguma regra, a ausência dos dados poderá causar <b>sérias divergências</b> no fluxo de tomada de decisão.
                    </S.Description>
                    <S.Description>Para prosseguir, escreva o nome da lista no campo de texto:</S.Description>
                    <S.CommandText>{commandText}</S.CommandText>
                    <S.ValidationTextField
                        fullWidth
                        inputProps={{maxLength: commandText.length, style: {textAlign: "center"}}}
                        defaultValue={content}
                        onChange={(e) => setContent(e.target.value)}
                        onCut={handleClipboard}
                        onCopy={handleClipboard}
                        onPaste={handleClipboard}
                        correct={content === commandText}
                    />
                    <S.ButtonWrapper>
                        <S.FilledButton onClick={handleCancel}>Cancelar</S.FilledButton>
                        <S.OutlinedButton enabled={content === commandText} loading={deletionLoading} onClick={handleDelete}>
                            {deletionLoading ?
                                <CircularProgress color="#CE2C31" size={18}/> : 'Excluir'
                            }
                        </S.OutlinedButton>
                    </S.ButtonWrapper>
                </DialogContent>
            </Dialog>
        )
    }
}

export default DeleteListDialog;