import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9000;
    display: ${(props) => props.$hide ? "none" : "flex"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000055;
    backdrop-filter: blur(4px);
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    background-color: #fafafa;
    padding: 36px 48px;
    border-radius: 16px;
    z-index: 9999;
`;

export const Title = styled.p`
    font-size: 32px;
    margin: 0;
    padding: 0;
`;

export const Description = styled.p`
    font-size: 18px;
    margin: 0;
    padding: 0;
`;

export const Warning = styled.p`
    font-size: 14px;
    color: red;
`;

export const Callout = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
`;

export const LogoQI = styled.img`
    height: 160px;
    width: auto;
`;

export const ReconnectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    height: 80px;
`;

export const ReconnectText = styled.p`
    padding: 0;
    margin: 0;
    font-size: 12px;
`;